import {Col, Row} from "antd";
import {CalendarOutlined, CheckSquareOutlined, DeploymentUnitOutlined, InteractionOutlined,FundProjectionScreenOutlined} from '@ant-design/icons'

export default function Process({id}) {

    const process = [
        {
            'icon': <CheckSquareOutlined/>,
            'heading': "Submit",
            'description': "Add issues to your project board after the onboarding process.\n"

        },
        {
            'icon': <CalendarOutlined/>,
            'heading': "Refine",
            'description': "If we need further information from you, we will get back to you with you asap."

        },
        {
            'icon': <DeploymentUnitOutlined/>,
            'heading': "Build",
            'description': "Receive your fully working marketing website within a few weeks or even days.\n"

        },
        {
            'icon': <InteractionOutlined/>,
            'heading': "Iterate",
            'description': "Limitless Revisions for Your Perfect Marketing Website: Your Satisfaction, Our Mission.\n"

        }
    ]
        const gridData=[
                {
                        "icon":'',
                        "heading":'Make It Sticky',
                        "description":'We fill the website with engaging content that motivates customers to choose YOUR business.'
                },
                {
                        "icon":'',
                        "heading":'Facilitate & Collaborate',
                        "description":'We work together with you to make sure our designs reflect your own vision and ideas.'
                },
                {
                        "icon":'',
                        "heading":'Identify The Gap',
                        "description":'We analyze the market and competitor websites to discover what is missing.'
                },
                {
                        "icon":'',
                        "heading":'Optimize & Repeat',
                        "description":'We won’t rest until your website and its content are absolutely flawless.'
                },
        ]
    return <div id={id}>
        <Col>
            <Row align={'middle'}
                 justify={'center'}
                 style={{width: "100vw"}}
            >
                <h1>
                    A straight Forward Process
                </h1>
            </Row>
            <Row align={'middle'}
                 justify={'center'}
                 style={{width: "100vw"}}
            >
                <p>
                    Zero complications

                </p>
            </Row>
            <Row align={'middle'}
                 justify={'center'}
                 style={{width: "100vw"}}
            >
                {process.map(val => <Card description={val.description} Heading={val.heading} IconTag={val.icon}/>)}
            </Row>
        </Col>
        <Col>
            <Row align={'middle'}
                 justify={'center'}
                 style={{width: "100vw"}}
            >
                <h1>
                    The Dorse Approach
                </h1>
            </Row>
            <Row align={'middle'}
                 justify={'center'}
                 style={{width: "100vw"}}
            >
                <p>
                    Our area of practice is quite wide: design, graphics, branding. The experts at our web design studio
                    know exactly how to make your project unique.
                </p>
            </Row>
            <div
                 style={{width: "100vw",display:'flex', flexDirection:'column',border:'1px solid black',margin:'10px',borderRadius:'23px'}}
            >
                    <div style={{display:'flex',flexDirection:'row', alignItems:'center',justifyContent:'center'}} >
                            <div style={{width:'40%', display:'flex' ,flexDirection:'column',alignItems:'center'}}>
                                    <FundProjectionScreenOutlined />
                                    <h1>Understand Your Audience</h1>
                                    <p>We carefully research your target market and demographics of potential customers.</p>
                            </div>
                            <div style={{ width:'40%',display:"grid",gridTemplateColumns:'1fr 1fr'}}>
                                    {
                                            gridData.map(val=><Grid {...val}/>)
                                    }
                            </div>
                    </div>
            </div>
        </Col>

    </div>
};

const Grid=({icon,heading,description})=> {
        return <div style={{width:'70%'}}>
                <div>
                        {icon}
                </div>
                <h1> {heading}</h1>
                <p>{description}</p>
        </div>
}

const Card = ({IconTag, Heading, description}) => {
        return <Col style={{width: '20vw'}}>
                <Row>
                        {IconTag}
                </Row>
                <Row>
                        <h2>
                                {Heading}
                        </h2>
                </Row>
                <Row>
                        <p>
                                {description}
                        </p>
        </Row>
    </Col>
}