import {AndroidOutlined,CodeOutlined,DingdingOutlined,FundProjectionScreenOutlined,ProjectOutlined,VideoCameraOutlined} from '@ant-design/icons'
export default function Service({id}) {
    const servicesData = [
        {
            "icon": <CodeOutlined />,
            'heading': 'Web Development',
            'description': 'We create responsive, user-friendly, and secure websites that are optimized for search engines and designed to convert visitors into customers.',
            'points': [
                'Front-end Development',
                'Back-end Development',
                'Website Hosting',
                'Website Maintenance'
            ]
        },
        {
            "icon": <AndroidOutlined />,
            'heading': 'App Development',
            'description': 'We build mobile applications for iOS and Android devices that are intuitive, user-friendly, and designed to deliver a seamless user experience.',
            'points': [
                'Hybrid App Development',
                'App Deployment',
                'App Optimization',
                'App Maintenance'
            ]
        },
        {
            "icon": <DingdingOutlined />,
            'heading': 'Design',
            'description': 'We providing best designing the visual appearance and user-centered functionality of a website or mobile application, graphics design.',
            'points': [
                'Websites Design',
                'UI/UX',
                'Graphic design',
                'Software Interfaces'
            ]
        },
        {
            "icon":<FundProjectionScreenOutlined />,
            'heading': 'Marketing',
            'description': 'We can help businesses develop and execute digital marketing strategies to reach and engage their target audiences.',
            'points': [
                'Search Engine Optimization (SEO)',
                'Social Media Marketing',
                'Content Marketing',
                'Influencer Marketing',
                'Email Marketing'
            ]
        },
        {
            "icon": <ProjectOutlined />,
            'heading': 'Article Writing and Promotion',
            'description': 'Streamlined Technical Documentation for Effortless User Understanding and Product Excellence.',
            'points': [
                'User Manuals',
                'API Documentation',
                'Product Guides',
                'Process Documentation',
                'Training Materials'
            ]
        },
        {
            "icon":<VideoCameraOutlined />,
            'heading': 'Motion & Branding Design',
            'description': 'Motion and branding services are creative services that can help businesses to create a visual brand identity and apply it across various marketing materials. We can help businesses create animated logos, explainer videos, social media animations.',
            'points': []
        }
    ]
    return <div id={id}>
        <div>
            <div>
                <h1>Services</h1>
            </div>
            <div>
                <p>Empowering Your Growth with Our Services</p>
            </div>
        </div>
        <div style={{display:'grid',gridTemplateColumns:'1fr 1fr 1fr'}}>
            {
                servicesData.map(val=><ServiceCard {...val}/>)
            }
        </div>
        <div style={{marginTop:'2.5rem',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',width:'100vw'}}>
            <div style={{width:'50%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                Image
            </div>
            <div  style={{width:'50%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                <h1>
                    Elevate Your Vision with Our Unrivaled Expertise
                </h1>
                <p>
                    Selecting us is choosing the pinnacle of excellence. Our unwavering commitment to your triumph is matched
                    only by our relentless pursuit of innovation in IT and development services. Behind every success story
                    lies our team of dedicated experts, driven to grasp your distinct requirements and deliver tailor-made
                    solutions. We are the paragon of precision, punctuality, and support, ensuring your vision evolves into
                    an extraordinary reality. With us by your side, your business will not just thrive but soar to
                    unparalleled heights. Join us in our journey to craft unparalleled greatness.
                </p>
                <p>
                    At Dorse, we embrace the challenge of bringing fresh ideas and unique designs to every project,
                    ensuring that our clients receive services that are innovative, visually appealing, and effective
                    in achieving their business goals.
                </p>
            </div>
        </div>
    </div>
}


const ServiceCard=({icon,heading,description,points})=>{

    return <div style={{margin:'1rem'}}>
        <div >
            {icon}
        </div>
        <div>
            <h1>{heading}</h1>
        </div>
        <div>
            {description}
        </div>
        <div>
            {points.map(val=><div>+ {val}</div>)}
        </div>
    </div>
}

