import {Button} from "antd";
import {Link} from "react-router-dom";

export default  function Collaboration({id}){
    const benifits=[
        {
            'icon':'',
            "heading":'Calculable Monthly Costs'
        },
        {
            'icon':'',
            "heading":'Get 24/7 Control'
        },
        {
            'icon':'',
            "heading": 'Fast & Professional',
        },
        {
            'icon':'',
            "heading":'Spend Time on your business',
        },
        {
            'icon':'',
            "heading":'Flexibility is important',
        },
        {
            'icon':'',
            "heading":'Satisfaction Guarantee'
        },




    ]
    return <div id={id}>
        <div>
            <h1>Let's collaborate to achieve excellence together!</h1>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            <div  style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <h1>Have you project in mind?</h1>
                ICON
                <Link to={'/contact'}><Button>Let's Talk</Button></Link>
                <p>We would love to hear more about your project</p>
            </div>
            <div>
                <h1>Benefits With Us</h1>
                {
                    benifits.map(val=><BenifitCard {...val}/>)
                }

            </div>

        </div>

        <div style={{display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center',margin:'1rem'}}>
                <h1>
                    The only platform that can support your company at any scale
                </h1>
            <p>Effortlessly manage content,currate assortment, and leverage AI-generated content for personalized customer interactions</p>
            <div style={{display:'flex', flexDirection:'row'}}>
               <Link to={'/contact'}> <Button>Request a Demon</Button></Link>
                {/*<Button>Remind me Later</Button>*/}
            </div>
        </div>
    </div>;
}

const BenifitCard=({icon,heading})=>{
    return <div style={{display:'flex',flexDirection:'row'}}>
        icon
        <h3>{heading}</h3>
    </div>
}