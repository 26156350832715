import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./Components/Home/Home";
import ContactUs from "./Components/ContactUs/ContactUs";
import Navbar from "./Components/Navbar/Navbar";
function App() {
  return (
      <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path={'/'} element={<Home/>}/>
          <Route path={'/contact'} element={<ContactUs/>}/>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
