import {Button, Col, Row} from "antd";
import {Link} from "react-router-dom";

 export default function Navbar(){

    return <Row  justify={'space-between'} align={'middle'} style={{width:'100vw',height:'10vh'}}>
            <Col>
                <h1>
                 <Link to={'/'}>Dorse</Link>
                </h1>
            </Col>
        <Col style={{width:'60vw'}}>
            <Row style={{width:'60vw'}} align={'middle'} justify={'space-evenly'}>
                <Col>
                   <a href={'/#process'}> Process</a>
                </Col>
                <Col>
                    <a href={'/#works'}>Work</a>
                </Col>
                <Col>
                    <a href={'/#services'}>Services</a>
                </Col>
                <Col>
                    Testimonials
                </Col>
                <Col>
                    <a href={'/#faq'}>FAQs</a>
                </Col>
                <Col>
                    <Link to={'/contact'}><Button>Contact Us</Button></Link>
                </Col>
            </Row>
        </Col>
    </Row>;
}