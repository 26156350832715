import React from 'react';
import { Collapse, Typography } from 'antd';

const { Paragraph } = Typography;
const { Panel } = Collapse;
export default  function FAQ({id}){

    const FAQData=[
        {
            'heading':'How quick will I get my landing page?',
            'text':'Considering industry standards, we are quick. Usually we deliver ' +
                'a full working website within 14 days. It will always depending on the ' +
                'scope of your project. Bigger projects can take to one month or more.'
        },
        {
            'heading':'Is it there any limited amount of requests I can submit?',
            'text':"Dorse Is unlimited, which means you can submit as many requests you" +
                " want. We will deliver one by one and prioritise respectively the list in Slack."
        },
        {
            'heading':'Which project management tool are you using?',
            'text':'We use Slack Channel to manage projects, once we get to work you will be added to the your own private workspace.'
        },
        {
            'heading':'Do you offer hosting?',
            'text':'Dorse offers hosting services. We provide various types of hosting services, including Shared Hosting, Virtual Private ' +
                'Server (VPS) Hosting, Dedicated Server Hosting. your site is fast, secure and always up.'
        },
        {
            'heading':'What if I am not happy with the deliveries?',
            'text':'If for any reason you are not completely satisfied with our work, we will do everything in our power to make it right. Your satisfaction is ' +
                'our top priority and we are committed to ensuring that you are fully happy with the final product.'
        },
    ]
    return <div id={id}>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
            <div>
               <h1> Frequently Asqued Questions</h1>
            </div>
            <div>
                {
                    FAQData.map(val=><CollapseFAQ {...val}/>)
                }
            </div>

        </div>
    </div>
}

const CollapseFAQ=({heading,text})=>{

    return  <Collapse accordion >

        <Panel header={heading} key="1">
            <Paragraph>
                {text}
            </Paragraph>
        </Panel>
    </Collapse>
}