import Navbar from "../Navbar/Navbar";
import {Col, Row} from "antd";
import Process from "../Process/Process";
import Service from "../Service/Service";
import Works from "../Works/Works";
import Collaboration from "../Collaboration/Collaboration";
import FAQ from "../FAQ/FAQ";
import Footer from "../Footer/Footer";


export default function Home(){
   return <>
        {/*<Navbar/>*/}
       <div className={'hero-wrapper-class'}>
           <div className={'card'}>
               <div className={'upper-section'}>
                   <p>👍</p><p>Handle all your Digital Requirement</p>
               </div>
               <div className={'middle-section'}>
                   <div className={'headingDiv'}>
                       <h1 className={'heading'}>
                           Code, Create, Catalyze :
                       </h1>
                   </div>
                   <div>
                       <h1 className={'heading'}>
                           <span>Your Success</span>, Our Mission
                       </h1>
                   </div>
                   <div className={'subheading-div'}>
                       <p className={'subheading'}>
                           Empowering Your Online Success: Ready to Transform Your Digital Presence?
                       </p>
                   </div>
                   <button className={'button'}>Get Started</button>
               </div>
               <div></div>
           </div>
       </div>
       <Process id={'process'}/>
       <Works id={'works'}/>
       <Service id={'services'}/>
       <Collaboration id={'collab'}/>
       <FAQ id={'faq'}/>
       <Footer/>
   </>
}