import {Field, Form, Formik} from "formik";
import * as Yup from 'yup';
export default function ContactUs() {
    const SignupSchema = Yup.object().shape({
        firstName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        lastName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        phone:Yup.string().min(3,'Phone Number is not valid'),
        domain:Yup.string().min(2,"Select a valid Option").required('Required'),
        message:Yup.string().required('Required')
    });

    return <div>
        <div>
            <div>
                <h1>Get In Touch</h1>
            </div>
            <div>
                <Formik initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    domain: '',
                    message: ''
                }}
                        validationSchema={SignupSchema}
                        onSubmit={
                    (formData) => {
                        console.log(formData)
                    }
                }>
                    {({isSubmitting,values,errors,touched}) => (
                        <Form>
                            <div>
                                <div>
                                    <label htmlFor={'firstName'}>First Name</label>
                                    <Field type={'text'} name={'firstName'} placeholder={'Enter First Name'}/>
                                    {errors.firstName && touched.firstName ? (
                                        <div>{errors.firstName}</div>
                                    ) : null}
                                </div>
                              <div>
                                  <label htmlFor={'lastName'}>Last Name</label>
                                  <Field type={'text'} name={'lastName'} placeholder={'Enter Last Name'}/>
                                  {errors.lastName && touched.lastName ? (
                                      <div>{errors.lastName}</div>
                                  ) : null}
                              </div>
                            </div>
                            <div>
                                <label htmlFor={'email'}>Email</label>
                                <Field type="email" name="email" placeholder="Enter email address"/>
                                {errors.email && touched.email ? <div>{errors.email}</div> : null}
                            </div>
                            <div>
                                <label htmlFor={'phone'}>Phone Number</label>
                                <Field type="number" name="phone" placeholder="Enter Mobile Number"/>
                                {errors.number && touched.number ? <div>{errors.number}</div> : null}

                            </div>
                            <div>
                                <label htmlFor={'domain'}>Domain</label>
                                <Field as="select" name="domain"
                                       value={values.domain === '' ? "Select an Option" : values.domain}
                                    // onChange={(val) => values.domain = val.target.value}
                                >
                                    <option value="" disabled={false}>Select an Option</option>
                                    <option value="STRATEGIC CALL">STRATEGIC CALL</option>
                                    <option value="AI/ML">AI/ML</option>
                                    <option value=" ANDROID DESIGN/DEVELOPMENT"> ANDROID DESIGN/DEVELOPMENT</option>
                                    <option value="UI/UX">UI/UX</option>
                                    <option value="WEB DESIGN/DEVELOPMENT">WEB DESIGN/DEVELOPMENT</option>
                                    <option value="MARKETING/CONTENT WRITING">MARKETING/CONTENT WRITING</option>
                                    <option value="EDITING,BRANDING/LOGO">EDITING,BRANDING/LOGO</option>
                                </Field>
                                {errors.domain && touched.domain ? <div>{errors.domain}</div> : null}

                            </div>
                            <div>
                                <label htmlFor={'message'}>Message</label>
                                <Field type={'text'} name={'message'} placeholder={"Enter your Message Here"}/>
                                {errors.message && touched.message ? <div>{errors.message}</div> : null}

                            </div>
                            <Field type={'submit'} name={"Submit"}/>
                        </Form>
                    )}
                </Formik>
                {/*
          Message
            */}
            </div>
        </div>
    </div>
}