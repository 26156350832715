import React, { useState, useEffect } from 'react';
import {Button} from "antd";
import {Link} from "react-router-dom";
export default  function Works({id}){
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isRunning, setIsRunning] = useState(true);
    const data=[
        {
            'heading':'The Collectica Store',
            'image':''
        },
        {
            'heading':'ToLet Flat/PG Rentals',
            'image':''
        },
        {
            'heading':'Text to Video Convertor Application',
            'image':''
        },
        {
            'heading':'Design',
            'image':''
        },
    ];

    // Function to handle next slide
    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    };

    const stopCarousel = () => {
        setIsRunning(false);
    };

    useEffect(() => {
        let intervalId;

        if (isRunning) {
            intervalId = setInterval(() => {
                nextSlide();
            }, 2000);
        }

        return () => clearInterval(intervalId);
    }, [currentIndex, isRunning, data.length]);

    return <div id={id}>
        <div  style={{width: '100vw', display: 'flex', flexDirection: 'row'}}>
            <div style={{
                width: "50%",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <h1>Eminent Work</h1>
                <p>Explore Our Latest Noteworthy Projects Crafted for Our Esteemed Clients.</p>
                <p>
                    These represent a fraction of our notable undertakings. At Dorse, our dedication to excellence
                    shines through a fusion of pioneering design, advanced development, and strategic marketing.
                    Our mission is to propel businesses towards success, and we eagerly anticipate furthering our
                    legacy of crafting exceptional solutions.
                </p>
                <div>
                    {
                        data.map((val, index) => <div onClick={() => {
                            setIsRunning(false);
                            setCurrentIndex(index);
                        }} style={{
                            backgroundColor: currentIndex === index ? 'black' : 'white',
                            color: currentIndex === index ? 'white' : 'black'
                        }}>
                            + {val?.heading}
                        </div>)
                    }
                </div>
            </div>
            <div>
                <img src={data[currentIndex]?.image} alt={data[currentIndex]?.heading}/>
            </div>

        </div>
        <Link to={'/contact'}><Button>Get Started</Button></Link>
    </div>
}